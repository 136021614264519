import React, { ReactElement } from "react";
import Form from "@components/shared/form";
import styles from "./styles.module.scss";
import { useDomainContext } from "@hooks/useDomainContext";
import { useRouter } from "next/router";
import { finalShowForm } from "@lib/sharedUtils";
import TextBlockOffer from "@components/shared/textBlockOffer/TextBlockOffer";

export default function MainBanner(): ReactElement {
    const { visitDetails, domain } = useDomainContext();
    const router = useRouter();

    const isFormDisplayed = finalShowForm(
        router.query.sl as string,
        domain.showForm,
        domain.defaultOffers,
    );
    return (
        <>
            {isFormDisplayed ? (
                <div className={styles["main-banner"]}>
                    <div
                        className={`flex justify-center ${styles["main-banner-inner"]}`}
                    >
                        <Form
                            classes={{
                                formClassName: styles["form"],
                                stepTitleClassName: styles["step-title"],
                                formProgressbarClassName: styles["progress"],
                                stepClassName: styles["step-block"],
                                stepDescriptionClassName:
                                    styles["step-description"],
                                formButtonClassName: styles["form-button"],
                                formFieldClassName: styles["form-field"],
                                formStepInnerClassName: styles["steps-inner"],
                                fieldsClassName: {
                                    radio: styles["radio-field"],
                                    zipcodeExpandable:
                                        styles["zipcode-expandable"],
                                },
                                fieldsWrapperClassName:
                                    styles["fields-wrapper"],
                                tcpaClassName: styles["tcpa"],
                                labelAsTitleClassName:
                                    styles["label-as-step-title"],
                                sellingPointClassName: styles["selling-point"],
                            }}
                            colors={{
                                progressBar: "#b1b1b1",
                                lightPrimaryColor: "#efefef",
                            }}
                            backButton={{
                                class: styles["back-button"],
                                label: "Back",
                            }}
                            showProgress={true}
                            preventWindowClose
                            popupStyle={{
                                actionBtnBgColor: "#23514e",
                                iconColor: "#23514e",
                            }}
                            sellingPoint={
                                visitDetails?.region
                                    ? `${visitDetails?.region} Best Car Accident Lawyers`
                                    : ""
                            }
                            sellingPointsSteps="FIRST_STEP"
                            goToThankYouPage={true}
                        />
                    </div>
                </div>
            ) : (
                <TextBlockOffer />
            )}
        </>
    );
}
