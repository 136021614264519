import React, { ReactElement } from "react";

import { GetServerSideProps } from "next";
import dynamic from "next/dynamic";
import { getPageProps } from "@services/initial-calls";
import { useDomainContext } from "@hooks/useDomainContext";
import MaxInjuryCompensationWrapper from "@components/layouts/maxinjurycompensationWrapper";

import MainForm from "@components/maxinjurycompensation/mainBanner";

const OffersList = dynamic(() => import("@components/shared/offersList"));
import FileCarAccident from "@components/maxinjurycompensation/fileCarAccident";
import GoCourt from "@components/maxinjurycompensation/goCourt";
import MiddleSection from "@components/maxinjurycompensation/middleSection";
import FinalSection from "@components/maxinjurycompensation/finalSection";
import WhyUs from "@components/maxinjurycompensation/whyUs";
import Disclaimer from "../../src/components/shared/legal/Disclaimer";
export default function LongRangeMoversHome({
    locale,
}: {
    locale: string;
}): ReactElement {
    const { domain } = useDomainContext();
    return (
        <MaxInjuryCompensationWrapper>
            <MainForm />
            <section>
                <OffersList offers={domain?.defaultOffers} />
            </section>
            {locale !== "es" ? (
                <>
                    <FileCarAccident />
                    <GoCourt />
                    <MiddleSection />
                    <FinalSection />
                    <WhyUs />
                    <Disclaimer />
                </>
            ) : (
                <></>
            )}
        </MaxInjuryCompensationWrapper>
    );
}

export const getServerSideProps: GetServerSideProps = async (context) => {
    return await getPageProps(context, "home");
};
